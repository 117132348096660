import React from "react";
import { Link } from "react-router-dom";
import CardButton from "../CardButton";
interface ICard{
    rankNumber?: string,
    name?: string,
    rankImage: string,
    className?: string,
    rankStyle?: string;
    textStyle?: string;
    cardTag?: string;
    cardTagStyles?: string;
    cardNum?: string;
    img?: string;
    disabled?: boolean;
    cardLink?: string;
}
const Card: React.FC<ICard> = ({className, img, cardLink, rankNumber, name, rankImage, rankStyle, textStyle, cardTag, cardTagStyles, cardNum, disabled=false })  => {
    return (
        <div className={`w-rankBox relative rounded-lg bg-btnColor p-0.5 ${className}`}>
            
            <div className="image-holder relative">
                {cardTag && <div className={`tag ${cardTagStyles}`}>{cardTag}</div>}
                {rankNumber &&<div className={`text-center text-white font-medium bg-rankColor backdrop-blur-rankBlur py-0.5 rounded-t-lg absolute top-0 left-0 right-0 ${rankStyle}`}>{rankNumber}</div>}
                <img src={rankImage} alt="rankImage" className="w-full rounded-t-lg m-auto" />
            </div>
                {name &&
                <div className={`relative text-holder bg-rankingBlock  text-center rounded-b-lg text-white py-2 ${textStyle}`}>
                    {name && <h2 className="text-base md:text-lg font-k2D py-1 bg-btnColor">{name}</h2>}
                     <div className="flex flex-wrap items-center justify-between mt-1 font-JoSefin-sans text-base md:text-lg font-medium">
                        <CardButton className="disabled" btnText={disabled ? "Coming Soon" : "Buy Now"} img="assets/images/buys.svg" disabled={disabled}/>
                        <Link to={`${cardLink}`}><CardButton btnText="View" img="assets/images/eyes.svg"/></Link>
                    </div>
                </div>
                }
        </div>
    );
}
export default Card;