import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Back } from '../../assets/svgs';
import { useNavigate } from 'react-router-dom';

const ArenaDetail= () => {
  const navigate = useNavigate()
  return (
    <>
      <div
      className="flex justify-between flex-col wrapper bg-no-repeat bg-cover bg-bgPosition overflow-hidden"
      style={{ backgroundImage: `url('assets/images/bg-detail.png')` }}
      >
        <Header />
        <div className='main-section flex-grow-1 basis-0'>
          <div className="relative pt-3 md:pt-6 pb-4 md:pb-8 sm:pt-12 sm:pb-20">
            <div className='absolute -bottom-30 left-0 z-1'>
            <img
                  src="assets/images/left-mountain.png"
                  className="h-auto max-w-full"
                  alt="dragon" />
            </div>
            <div className=" block relative max-w-containerLarge m-auto px-4 sm:flex sm:justify-between z-50">
                <span className="block mb-2 xl:mb-4 lg:w-1/4 sm:w-1/6 " onClick={()=> navigate('/arena')}>
                  <span className="inline-flex flex-wrap items-center text-white cursor-pointer">
                    <span className="icon mr-3"><Back /></span> Back
                  </span>
                </span>
                <div className='sm:w-full text-center lg:pr-9 -ml-8 sm:-ml-6 pl-8'>
                  <div className='sm:mb-10 mb-2 m-auto'>
                    <div className='w-20 m-auto mb-2'>
                      <img
                        src="assets/images/slide-2.png"
                        className="h-auto max-w-full"
                        alt="dragon" /> 
                    </div>  
                  <div className='sm:w-2/3 m-auto'>
                    <h1 
                    className="block sm:font-extrabold text-fGray font-k2D text-2xl sm:text-4xl bg-clip-text text-transparent bg-gradient-to-r from-textForm to-textPeach mb-5">
                      DragonBound
                    </h1>
                    <p className='block text-white  mb-4 text-xs lg:text-base'>
                    DragonBound is our homage to the '90s classic, Worms Armageddon. In this thrilling turn-based strategy game, players command their dragon armies and utilize a diverse arsenal of weapons to outmaneuver and defeat their opponents. With engaging gameplay, strategic depth, and a touch of nostalgia, DragonBound promises to keep you entertained and coming back for more.
                    </p>
                  </div>
                  <div className='pt-10 sm:flex'>
                    <div className="w-full mr-4 mb-4 rounded-3xl bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 pr-2 pt-2 pl-2 pb-2">
                        <img
                        src="assets/images/dargongame-1.png"
                        className=" w-full rounded-3xl"
                        alt="dragon" />
                    </div>  
                    <div className="w-full rounded-3xl mb-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 pr-2 pt-2 pl-2 pb-2">
                        <img
                        src="assets/images/dargongame-2.png"
                        className="h-auto w-full rounded-3xl"
                        alt="dragon" />
                    </div> 
                  </div>
                </div>
            </div>
          </div>
        </div>  
        <Footer />
        </div>
      </div>
    </>
    );
}

export default ArenaDetail;
