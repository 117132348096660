import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import BannerResult from '../../Components/BannerResult';

const Result= () => {
  return (
    <>
      <div
      className="flex justify-between flex-col wrapper bg-no-repeat bg-cover bg-bgPosition overflow-hidden"
      style={{ backgroundImage: `url('assets/images/bg-detail.png')` }}
      >
      <Header />
      <div className='main-section flex-grow-1 basis-0'>
      <BannerResult />
      </div>
      <Footer />
      </div>
    </>
    );
}

export default Result;
