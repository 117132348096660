import React, { ReactElement, ReactNode } from 'react'

interface IPlaceholder{
    className?: string,
    textPlace?: ReactElement | ReactNode ,
    imgPlace?: ReactElement | ReactNode,
}
const CircleImage: React.FC<IPlaceholder> = ({className, textPlace, imgPlace})=> {
  return (
    <div className='circle-block relative w-72'>
            <div className='circle'>
                <img
                    src="assets/images/circle.png"
                    className="h-auto w-full image-radius"
                    alt="Circle" />
            </div>
            <div className='absolute content-center'>
                <img
                    src="assets/images/square.png"
                    className="h-auto w-40 image-radius"
                    alt="Circle" />
            </div>
            <div className='absolute content-center inner-content'>
                <img
                    src="assets/images/turnsquare.png"
                    className="h-auto w-40 image-radius"
                    alt="Circle" />
            </div>
            <div className='title-area'>
                {imgPlace}
            </div>
        <div className='pt-2'>
            {textPlace}
        </div>
    </div>
  );
}

export default CircleImage;