import React from 'react';

const TableElement = ()  => {
    return (
        <div className="text-white pt-9">
            <div className="tableElement">
                <div className='elemntRow'>
                    <span className="elements">SELF 5 ELEMENTS</span>
                </div>
                <div className="selfRow">
                    <span className="firstRow  font-semibold">Self</span>
                    <div className="fillfirst  font-semibold flex justify-center items-center flex-col">Wealth <br/> <span className='text-fGray'> Lipsum</span></div>
                    <div className="fillfirst  font-semibold flex justify-center items-center flex-col">Carrers <br/> <span className='text-fGray'> Lipsum</span></div>
                    <div className="fillfirst  font-semibold flex justify-center items-center flex-col">Self <br/> <span className='text-fGray'> Lipsum</span></div>
                    <div className="fillfirst  font-semibold flex justify-center items-center flex-col">Parents <br/> <span className='text-fGray'> Lipsum</span></div>
                </div>
                <div className="rowArea">
                    <span className="firstRow text-2xl font-semibold">Me</span>
                    <span className="fillfirst text-2xl font-semibold">G</span>
                    <span className="fillfirst text-2xl font-semibold">0</span>
                    <span className="fillfirst text-2xl font-semibold">0</span>
                    <span className="fillfirst text-2xl font-semibold">G</span>
                </div>
                <div className="rowArea">
                    <span className="firstRow text-base font-semibold">Wood</span>
                    <span className="fillfirst text-base font-semibold">Fire</span>
                    <span className="fillfirst text-base font-semibold">Water</span>
                    <span className="fillfirst text-base font-semibold">Air</span>
                    <span className="fillfirst text-base font-semibold">Metal</span>
                </div>
                <div className="rowArea">
                    <span className="firstRow text-2xl font-semibold">5</span>
                    <span className="fillfirst text-2xl font-semibold">5</span>
                    <span className="fillfirst text-2xl font-semibold">1</span>
                    <span className="fillfirst text-2xl font-semibold">4</span>
                    <span className="fillfirst text-2xl font-semibold">1</span>
                </div>
            </div>
        </div>
    );
}
 
export default TableElement;