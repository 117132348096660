import React, { useEffect, useState } from 'react';
import Accordion from '../Accordion';

const nftItems = ['Heartstone', 'Pixiu Eggs', 'Pixiu Dragons'];
const clothingItems = ['Hoodies', 'T-shirts', 'Caps'];
const accessoryItems = ['Bracelets'];
const miscItems = ['Totebag'];


type IFilterProps = {
  onSelect: (item: any) => void;
  value: { label: string; value: string }[];
};

function ParentComponent(props: IFilterProps) {
  const [active, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value || []);
  }, [props.value]);

  const handleClick = (parent: string, child: string) => {
    props?.onSelect({ parent, child });
  };

  return (
    <div className="box-model searchbar max-w-cardcontainer">
      <div className="box-wrap">
        <div>
          <h2>Filter</h2>
        </div>
        <div>
          <Accordion
            title="Clothing"
            content={
              <ul className="accordion-list">
                {clothingItems.map((clothing) => (
                  <li
                    className={
                      active.find((a) => a.label === `Clothing-${clothing}`)
                        ? 'active_item'
                        : ''
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClick('Clothing', clothing);
                    }}
                  >
                    <a href="#">{clothing}</a>
                  </li>
                ))}
              </ul>
            }
          />
          <Accordion
            title="Accessories"
            content={
              <ul className="accordion-list">
                {accessoryItems.map((accessory) => (
                  <li
                    className={
                      active.find((a) => a.label === `Accessories-${accessory}`)
                        ? 'active_item'
                        : ''
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClick('Accessories', accessory);
                    }}
                  >
                    <a href="#">{accessory}</a>
                  </li>
                ))}
              </ul>
            }
          />
          <Accordion
            title="Misc"
            content={
              <ul className="accordion-list">
                {miscItems.map((misc) => (
                  <li
                    className={
                      active.find((a) => a.label === `Misc-${misc}`)
                        ? 'active_item'
                        : ''
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClick('Misc', misc);
                    }}
                  >
                    <a href="#">{misc}</a>
                  </li>
                ))}
              </ul>
            }
          />
          <Accordion
            title="NFTs"
            content={
              <ul className="accordion-list">
                {nftItems.map((nft) => (
                  <li
                    className={
                      active.find((a) => a.label === `NFTs-${nft}`)
                        ? 'active_item'
                        : ''
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClick('NFTs', nft);
                    }}
                  >
                    <a href="#">{nft}</a>
                  </li>
                ))}
              </ul>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ParentComponent;
