import { Link } from "react-router-dom";

interface IButton{
    className?: string,
    btnText: string,
}
const Button: React.FC<IButton> = ({className, btnText })  => {
    return (
        <div className="button">
             <div className="flex items-center absolute right-8 bottom-5 md:bottom-7 border-4 border-btnBorder rounded-lg">
            <Link
              to="/"
              className="font-JoSefin-sans flex items-center bg-btnBgClr text-white py-2 px-5 md:py-4 md:px-10 rounded-sm text-sm md:text-lg font-medium"
            >
              <span className="mr-4 ">
                <img src="assets/images/Simg.png" alt="Simg" />
              </span>
              {btnText}
            </Link>
          </div>
        </div>
    );
}
 
export default Button;