import React, {useState} from 'react'
import ParentComponent from '../ParenAccordion';

const Filter = () => {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <>
        <div className='searchbar py-3 px-3 relative filter-bar rounded-lg re' onClick={()=> setIsOpen(!isOpen)}>
        <img
            src="assets/images/filter.svg"
            className="h-auto max-w-full"
            alt="dragon" />         
        </div>
    </>
  )
}

export default Filter;