const ComingSoon = () => {
    return (
        <div className="ComingSoon text-white flex justify-center items-center w-full h-screen">
            <div className="max-w-textArea px-4 text-center">
                <h1 className="font-k2D text-4xl font-extrabold mb-4 uppercase bg-bgText text-transparent bg-clip-text">Coming Soon</h1>
                <p className="text-base text-titleClr">We are preparing for more perks for you to enjoy. See you soon!</p>
            </div>
        </div>
    );
}
 
export default ComingSoon;