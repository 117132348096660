import { Link } from 'react-router-dom';

const SocialNetworks = () => {
  return (
      
    <ul className="menu flex items-center md:pt-24 pt-9 mb-9 sm:mb-0">
        <li className='pr-5'>
            <Link to="https://discord.com/invite/xswipe">
                <img src="assets/images/discord.svg" alt="assets/images/discord.svg" />
            </Link>
        </li>
        <li className='pr-5'>
            <Link to="https://twitter.com/pixiuverse">
                <img src="assets/images/twitter.svg" alt="assets/images/twitter.svg" />
            </Link>
        </li>
    </ul>
  );
};

export default SocialNetworks;
