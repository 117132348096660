import React from "react";
import { Link } from 'react-router-dom';
import SocialNetworks from "../SocialNetworks";

const Home = () => {
   return (
    <div className="max-w-homeContainer m-auto px-4">
        <div className="two-cols mx-auto justify-between py-5 sm:pt-14 lg:pt-32 sm:pb-14 lg:pb-28 flex flex-col-reverse sm:flex-row">
            <div className="col relative sm:justify-center sm:flex">
                <div className="relative image flex justify-center bg-btnColor p-2.5 rounded-xl">
                    <img className="w-full" src="assets/images/img-home.png" alt="img" />
                </div>
            </div>
            <div className="sm:w-1/2 md:pt-12 md:pr-9 sm:pl-3 w-full sm:pt-0">
                <div className="image mb-2">
                    <img src="assets/images/home-snake.png" alt="img" />
                </div>
                <div className="text-holder">
                    <h1 className="text block font-extrabold sm:text-xl text-4xl lg:text-4xl font-k2D uppercase md:mb-5 mb-2 text-transparent bg-clip-text bg-gradient-to-r from-textForm to-textPeach">Welcome to Pixiuverse!</h1>
                    <p className="text-xs md:text-base text-fGray font-Poppins leading-normal md:mb-11 mb-6">
                    It's not just a platform, but a universe where art, Feng Shui, and NFTs meet 90's-inspired gaming with a modern twist. 
                    Our browser-based platform provides easy and speedy access to a world of Pixiu Dragon NFTs 
                    and retro-modern games, all designed to create an immersive, unique, and prosperous digital experience.
                    </p>
                    <div className="">
                        <Link
                            to="http://explorer.pixiuverse.io"
                            className="md:text-base bg-btnColor text-xs font-Poppins text-white md:pt-4 md:pb-5 md:px-8 sm:px-5 sm:py-4 px-2 py-2 rounded-lg md:mb-16 mb-9"
                            >
                            Explorer
                        </Link>
                    </div>
                    <SocialNetworks />
                </div>
            </div>
        </div>
    </div>
   )
}

export default Home