const Speacker = (props: React.SVGAttributes<SVGElement>) => {
  const { width = '32', height = '32', fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width="21"
      height="38"
      viewBox="0 0 21 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.5826V25.4816H9.26605L20.8486 37.0642V0L9.26605 11.5826H0Z"
        fill="url(#paint0_linear_125_675)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_125_675"
          x1="2.06828"
          y1="18.2372"
          x2="24.6809"
          y2="11.9848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#733381" />
          <stop offset="1" stop-color="#FB9A27" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Speacker;
