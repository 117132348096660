import React, { useState } from 'react';

function Accordion(props:any) {
  const [isOpen, setIsOpen] = useState(false);

  // toggle the accordion state
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion mb-4">
      <div className="accordion-header" onClick={toggleAccordion}>
        {props.title}
        <span className={`accordion-icon ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {props.content}
        </div>
      )}
    </div>
  );
}

export default Accordion;
