import React from 'react'
import TableNumbers from '../TableNumber';
import CircleImage from '../Circuler';
import TableElement from '../TableElement';

const Calculate = () => {
  return (
    <div className='max-w-containerLarge m-auto px-4'>
        <div className='block lg:flex pt-2 justify-between pb-4 md:pb-10'>
            <div className='w-1/3'>
            <CircleImage 
            imgPlace={
                <img
                  src="assets/images/img-four.png"
                  className="w-20 image-radius"
                  alt="Circle"
                />
              }
              textPlace={
                <span className="text-base block lg:pl-10">
                  <span className="text-fGray">Your</span> Life Path Number
                  <span className="text-fGray"> is</span> 4.
                </span>
              }
            />
            </div>
            <div className='w-full pt-6  md:pl-4 lg:w-3/4'>
                <div className='mb-8'>
                    <h2 className='mb-0 text-white text-2xl font-bold'>MY NUMBERS</h2>
                </div>
                <ul className='result-circle text-fGray '>
                <p>                
                  Your core numbers consist of several elements, including your Life Path Number, Expression Number, and Soul Urge Number, each playing a significant role in understanding your life's purpose. They offer guidance in navigating life's journey, revealing a unique set of strengths and weaknesses.
                </p>
                </ul>
                <TableNumbers tableName='Subconcio Us' tableNumFir='6' tableNumSec='9' tableNumThird='6' lack='Lack' tableNumForth='4, 5, 6, 7' />
                <div className='pt-9'>
                    <h3 className='mb-4  text-white'>Subconscious Self Number</h3>
                    <p className='mb-0 text-fGray leading-6'>
                      These numbers highlight areas where you may need to put in extra effort to attain balance and personal growth.
                    </p>
                </div>
                <div className='pt-9'>
                    <h3 className='mb-4  text-white'>Lack Numbers</h3>
                    <p className='mb-0 text-fGray leading-6'>
                      These numbers highlight areas where you may need to put in extra effort to attain balance and personal growth.
                    </p>
                </div>
                <div className='pt-9'>
                    <h3 className='mb-4  text-white'>ANALYSIS</h3>
                    <p className='mb-0 text-fGray leading-6'>
                    The analysis of your numerology chart offers profound insights into your personality, life's purpose, and destiny. It serves as a roadmap, guiding you towards a life of fulfillment and success.
                    </p>
                </div>
                <div className='pt-9'>
                    <h3 className='mb-4 text-white'>MY LUCKY NUMBER</h3>
                    <p className='mb-0 text-fGray leading-6'>
                    Your lucky number can provide guidance when making decisions, influencing your personal or professional life. It may bring good fortune and help in aligning with the universal flow.
                    </p>
                </div>
                <div className='pt-9'>
                    <h3 className='mb-4 text-white'>WHAT NUMBERS I SHOULD AVOID</h3>
                    <p className='mb-0 text-fGray leading-6'>
                    Certain numbers might be less harmonious with your vibrational energy and could lead to challenges or conflicts. Understanding these numbers can help you avoid potential hurdles on your life path.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Calculate;

