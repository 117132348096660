export const CardStore = [
  // NFTs
  {
    imageLink: "assets/images/dragons.webp",
    imageTitle: "Pixiu Dragons",
    tag: "NFTs",
    cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
    cardLink: "/dragons",
  },
  {
    imageLink: "assets/images/heartstone.webp",
    imageTitle: "Heartstone",
    tag: "NFTs",
    cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
    cardLink: "/heartstone",
  },
  {
    imageLink: "assets/images/eggs.webp",
    imageTitle: "Pixiu Eggs",
    tag: "NFTs",
    cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
    cardLink: "/eggs",
  },
  {
    imageLink: "assets/images/bracelet-3.webp",
    imageTitle: "Merch",
    tag: "Merch",
    cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
    cardLink: "/merch",
  },


// Clothing - Hoodies
// {
//   imageLink: "assets/images/hoodie-1.png",
//   imageTitle: "Hoodie-1",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/hoodie-2.png",
//   imageTitle: "Hoodie-2",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/hoodie-3.png",
//   imageTitle: "Hoodie-3",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/hoodie-4.png",
//   imageTitle: "Hoodie-4",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/hoodie-5.png",
//   imageTitle: "Hoodie-5",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// Clothing - T-Shirts
// {
//   imageLink: "assets/images/shirt-1.png",
//   imageTitle: "T-Shirt-1",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/shirt-2.png",
//   imageTitle: "T-Shirt-2",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/shirt-3.png",
//   imageTitle: "T-Shirt-3",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/shirt-4.png",
//   imageTitle: "T-Shirt-4",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/shirt-5.png",
//   imageTitle: "T-Shirt-5",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// Clothing - Caps
// {
//   imageLink: "assets/images/cap-white.png",
//   imageTitle: "Cap White",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// {
//   imageLink: "assets/images/cap-black.png",
//   imageTitle: "Cap Black",
//   tag: "Clothing",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
// Accessories - Bracelet
// {
//   imageLink: "assets/images/bracelet-1.webp",
//   imageTitle: "Bracelet-1",
//   tag: "Accessories",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
// {
//   imageLink: "assets/images/bracelet-2.webp",
//   imageTitle: "Bracelet-2",
//   tag: "Accessories",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
// {
//   imageLink: "assets/images/bracelet-3.webp",
//   imageTitle: "Bracelet-3",
//   tag: "Accessories",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
// {
//   imageLink: "assets/images/bracelet-4.jpg",
//   imageTitle: "Bracelet-4",
//   tag: "Accessories",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
// {
//   imageLink: "assets/images/bracelet-5.jpg",
//   imageTitle: "Bracelet-5",
//   tag: "Accessories",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
//
// Misc - Totebag
// {
//   imageLink: "assets/images/totebag.png",
//   imageTitle: "Totebag",
//   tag: "Misc",
//   cardStyle: "lg:w-cardWidth mx-1 lg:mx-2 mb-4 md:mb-7 md:w-cardTab w-mobileCardTab",
// },
];