
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import FormPage from '../../Components/Formpage'


const Numerology = () => {
  return (
    <div className='relative'>
        <div
            className="flex justify-between flex-col wrapper bg-no-repeat bg-cover bg-bgPosition overflow-hidden"
            style={{ backgroundImage: `url('assets/images/bg-firstpage.png')` }}>
            <Header />
            <div className='main-section flex-grow-1 basis-0'>
              <div className='pt-14 pb-6 sm:pb-44'>
                  <div className="text-center max-w-calcWidth m-auto px-1 pb-7">
                      <h1 
                      className="block sm:font-extrabold text-fGray font-k2D text-2xl sm:text-4xl bg-clip-text text-transparent bg-gradient-to-r from-textForm to-textPeach mb-4">
                      CALCULATE YOUR NUMEROLOGY
                      </h1>
                      <p className='text-fGray mb-2 text-base font-popins'>Enter your details on required fields below.</p>
                  </div>   
                  <FormPage />
              </div>
            </div>
            <Footer />
        </div>
    </div>
  )
}

export default Numerology;