import { Route, Routes } from 'react-router-dom';
import './App.css';
import Details from './Pages/Merch';
import LeaderBoard from './Pages/LeaderBoard';
// import FormPage from './Components/Formpage';
import ArenaDetail from './Components/ArenaDetail';
import Match from './Components/match';
import Arena from './Pages/Arena';
import Numerology from './Pages/Numerology';
import Result from './Pages/Result';
import Store from './Pages/Store';
import Travel from './Pages/Travel';
import Dragons from './Pages/Dragons';
import HeartStone from './Pages/HeartStone';
import Eggs from './Pages/Eggs';
import Merch from './Pages/Merch';

function App() {
  return (
    <>
      <Routes>
        <Route path="/Store" element={<Store />} />
        <Route path="/Travel" element={<Travel />} />
        <Route path="/" element={<LeaderBoard />} />
        <Route path="/dragons" element={<Dragons />} />
        <Route path="/heartstone" element={<HeartStone />} />
        <Route path="/eggs" element={<Eggs />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/numerology" element={<Numerology />} />
        <Route path="/arenaDetail" element={<ArenaDetail />} />
        <Route path="/match" element={<Match />} />
        <Route path="/arena" element={<Arena />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </>
  );
}

export default App;
