import { useEffect, useRef, useState } from 'react';

import { Speacker } from '../../assets/svgs';
import './audioplayer.css';
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */

const RAudioPlayer = () => {
  // State

  const [duration, setDuration] = useState(0);
  const [trackProgress, setTrackProgress] = useState(1);

  // Destructure for conciseness
  const audioSrc = '/assets/bards-tale.mp3';

  // Refs
  const audioRef = useRef<any>();

  const currentPercentage = duration ? `${trackProgress * 100}%` : '100%';

  const onScrub = (value: any) => {
    if (audioRef.current) {
      audioRef.current.volume = value;
      setTrackProgress(value);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDuration(audioRef?.current?.duration);
      audioRef?.current?.play();
    }, 1000);
    return () => {};
  }, []);

  return (
    <div className="audio-player">
      <div className="track-info">
        <audio src={audioSrc} ref={audioRef} />
        <Speacker />
        <div className="range-slider">
          <div className="filled-audio" style={{ width: currentPercentage }} />
          <input
            type="range"
            value={trackProgress}
            step="0.2"
            min="0"
            max={1}
            className="progress"
            onChange={(e) => onScrub(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default RAudioPlayer;
