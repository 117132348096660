import React from 'react';
import { Link } from 'react-router-dom';
import Button from "../Button";

const Banner = () => {
  return (
    <div className="mb-10 pt-5">
      <div className="max-w-containerBanner m-auto px-4">
        <div className="banner relative">
          <div className="p-5 md:pt-10 md:pl-8 md:pb-8 md:pr-9 bg-cover bg-no-repeat bg-bannerPosition mix-blend-lighten" style={{backgroundImage:`url(assets/images/banner.png)`}}>
            <div className="max-w-textHolder">
              <h2 className="font-k2D text-lg md:text-xl font-extrabold text-titleClr mb-4">FEATURED</h2>
              <h1 className="font-k2D text-3xl md:text-4xl font-extrabold text-white tracking-letterSpacing mb-2">PIXIU EGGS</h1>
              <p className="text-titleClr text-sm md:text-base font-normal mb-20 md:mb-16">
                A Mythical Pixiu egg that holds the potential to hatch into a powerful Pixiu. It can be combined with a total of 3 Pixiu Eggs to hatch a Pixiu. These Deflationary Egg NFTs are obtained through airdrops for holders of Solswipe NFTs. 10 Legendary 1/1s are within these!    
              </p>
            </div>
            <Link to="https://magiceden.io/marketplace/pixiudragon">
              <Button btnText='Buy Now' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
