import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import MySlider from '../../Components/MySlider';

const Arena= () => {
  return (
    <>
      <div
      className="flex justify-between flex-col wrapper bg-no-repeat bg-cover bg-bgPosition overflow-hidden"
      style={{ backgroundImage: `url('assets/images/bg-1.png')` }}
      >
      <Header />
      <div className='main-section flex-grow-1 basis-0'>
        <div className="relative pt-20  pb-4 sm:pt-16 sm:pb-24">
          <div className='absolute -bottom-30 left-0 z-1'>
              <img
                src="assets/images/left-mountain.png"
                className="h-auto max-w-full"
                alt="dragon" />
          </div>
          <div className='absolute -bottom-30 right-0 z-1'>
              <img
                src="assets/images/right-mountain.png"
                className="h-auto max-w-full"
                alt="dragon" />
          </div>
          {/* <div className='flex relative items-center'>
            <div className='absolute -top-16 md:-top-6 right-20 z-1'>
                <img
                  src="assets/images/speaker.png"
                  className="h-auto max-w-full"
                  alt="dragon" />
            </div>
            <div className='absolute -top-16 md:-top-6 right-10 z-1'>
                <img
                  src="assets/images/volume1.png"
                  className="h-auto max-w-full"
                  alt="dragon" />
            </div>
          </div> */}
          <div className="text-center max-w-arenaWidth m-auto px-1 pb-7 relative">
              <h1 
              className="block sm:font-extrabold font-k2D text-2xl sm:text-4xl bg-clip-text text-transparent bg-gradient-to-r from-textForm to-textPeach mb-4">
              WELCOME TO THE ARENA
              </h1>
              <p className='text-fGray mb-2 text-base font-popins'>
              The epicenter of competition within the Pixiuverse. Here, Pixiu holders clash in an array of 90's-inspired games with modern enhancements, engaging in winner-takes-all battles. Stake your tokens and challenge fellow Pixiu enthusiasts in thrilling contests. Our browser-based platform ensures fast, seamless access to this exciting world where skill, strategy, and prosperity intertwine. Enter the Arena and prove your prowess today!
              </p>
          </div> 
          <div>
              <MySlider />
          </div>
        </div>
      </div>  
      <Footer />
      </div>
    </>
    );
}

export default Arena;
