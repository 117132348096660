import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer bg-fBackColor border-borderColor border-t py-5 md:pt-5 md:pb-7 backdrop-blur-headerBlur relative">
      <div className="max-w-containerLarge mx-auto px-4 block md:flex md:items-center md:justify-between">
        <div className="text-center mb-4 md:m-0">
          <p className="block text-fGray">
            ©2023 Pixiu. All right reserved
          </p>
        </div>
        <ul className="flex items-center justify-center">
          <li className="m-2">
            <Link to="/" 
            className="text-white"
            >
            Terms & Condition
            </Link>
          </li>
          <li className="m-2">
            <Link to="/" className="text-white">
            Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
