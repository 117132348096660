import { useEffect, useState } from 'react';
import Banner from '../../Components/Banner';
import Card from '../../Components/Card';
import CloseableTabs from '../../Components/CloseableTabs';
import Filter from '../../Components/Filter';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import ParentComponent from '../../Components/ParenAccordion';
import Search from '../../Components/SearchArea';
import { CardStore } from '../../Utils/dump';



const Store = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState<any>([]);
  const [cards, setCard] = useState<any>(CardStore) 
   const [cloneCard, setCloneCard] = useState<any>(CardStore)

  const handleSelect = (active: any) => {
    const isExist = filters.find(
      (f: any) => f.value === `${active.parent}-${active.child}`
    );
    if (!isExist) {
      setFilters((f: any) => [
        ...f,
        {
          label: `${active.parent}-${active.child}`,
          value: `${active.parent}-${active.child}`
        }
      ]);
    }
  };

  const handleClose = (item: { label: string; value: string }) => {
    const { value } = item;
    if (value === 'clear_all') {
      setFilters([]);
      setCard(CardStore)
      return;
    }
    let newFilters = [...filters];
    newFilters = newFilters.filter((f) => f.value !== value);
    if (!newFilters.length) {
      setFilters([]);
      return;
    }
    setFilters(newFilters);
  };
    const handleSearch = (value: string) => {
    const filterCard = cloneCard.filter((item: any) => item.imageTitle.toLowerCase().includes(value.toLowerCase()) )
    setCard(filterCard)
    }

    useEffect(() => {
      let filtersArray: any = []
      if(filters.length != 0){
       cloneCard.filter((el: any) => {
       filters.find((element: any) => {
        if(element?.label?.toLowerCase().trim() == `${el?.tag?.toLowerCase()}-${el?.imageTitle?.toLowerCase()}`){
        filtersArray.push(el)
}
      });
   });

     setCard(filtersArray)
      }else {
        setCard(CardStore)
      }
   
    }, [filters])
  return (
    <>
      <div
        className="flex justify-between flex-col wrapper overflow-hidden bg-no-repeat bg-cover bg-storePosition"
        style={{ backgroundImage: `url('assets/images/bg-scroll.png')` }}
      >
        <Header />
        <div className="main-section flex-grow-1 basis-0">
          <div className="pt-10 pb-2 md:pt-12 md:pb-8">
            <Banner />
            <div className="relative">
              <div
                className="bars relative flex flex-wrap items-center justify-between max-w-storeSmallContainer m-auto px-4 bg-no-repeat bg-cover rounded-md pt-4 pb-4 mb-4"
                style={{ backgroundImage: `url('assets/images/bg-bar.png')` }}
              >
                <div  className={`${isOpen? "dropdown-open": ""} relative`}>
                  <div onClick={() => setIsOpen(!isOpen)} className='dropdown-opener'>
                    <Filter />
                  </div>
                  {isOpen && (
                    <div>
                      <ParentComponent onSelect={handleSelect} value={filters} />
                    </div>
                  )}
                </div>
                <Search handleSearch={handleSearch} />
              </div>
            </div>
            <CloseableTabs
              onClose={handleClose}
              tabs={
                filters.length > 0
                  ? [...filters, { label: `Clear All`, value: 'clear_all' }]
                  : []
              }
            />
            <div className="flex flex-wrap  max-w-storeSmallContainer m-auto px-4 cards-holder">
              {cards.map((link: any, i: any) => (
                  <Card key={i}
                    disabled={i===3 ?true : false}
                    rankImage={link.imageLink}
                    name={link.imageTitle}
                    cardLink={link.cardLink}
                    className={link.cardStyle}
                  />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Store;
