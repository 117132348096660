import React, { useState } from 'react';
import { string } from 'yup';

interface IMyButton {
  Btntext: string,
}

const MyButton: React.FC<IMyButton> = ({Btntext  })  => { 

  return (
    <button
      className={`flex items-center w-full sm:w-btnWidth mb-4 bgElement justify-center text-white py-2 md:py-4 leading-4 text-xs bd:text-base rounded-lg md:mx-0`}

    >
      {Btntext}
    </button>
  );
}

export default MyButton;