const MobileMenusvg = (props: React.SVGAttributes<SVGElement>) => {
  const { width = '28', height = '20', fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.421 0.399902C1.1381 0.399902 0.866787 0.512283 0.666748 0.712322C0.466709 0.912361 0.354329 1.18367 0.354329 1.46657C0.354329 1.74947 0.466709 2.02078 0.666748 2.22082C0.866787 2.42086 1.1381 2.53324 1.421 2.53324H26.8022C27.0851 2.53324 27.3564 2.42086 27.5564 2.22082C27.7565 2.02078 27.8688 1.74947 27.8688 1.46657C27.8688 1.18367 27.7565 0.912361 27.5564 0.712322C27.3564 0.512283 27.0851 0.399902 26.8022 0.399902H1.421ZM0.135498 9.9999C0.135498 9.71701 0.247879 9.4457 0.447917 9.24566C0.647956 9.04562 0.919267 8.93324 1.20216 8.93324H26.8022C27.0851 8.93324 27.3564 9.04562 27.5564 9.24566C27.7565 9.4457 27.8688 9.71701 27.8688 9.9999C27.8688 10.2828 27.7565 10.5541 27.5564 10.7541C27.3564 10.9542 27.0851 11.0666 26.8022 11.0666H1.20216C0.919267 11.0666 0.647956 10.9542 0.447917 10.7541C0.247879 10.5541 0.135498 10.2828 0.135498 9.9999ZM0.135498 18.5332C0.135498 18.2503 0.247879 17.979 0.447917 17.779C0.647956 17.5789 0.919267 17.4666 1.20216 17.4666H26.8022C27.0851 17.4666 27.3564 17.5789 27.5564 17.779C27.7565 17.979 27.8688 18.2503 27.8688 18.5332C27.8688 18.8161 27.7565 19.0874 27.5564 19.2875C27.3564 19.4875 27.0851 19.5999 26.8022 19.5999H1.20216C0.919267 19.5999 0.647956 19.4875 0.447917 19.2875C0.247879 19.0874 0.135498 18.8161 0.135498 18.5332Z"
        fill="white"
      />
    </svg>
  );
};
export default MobileMenusvg;
