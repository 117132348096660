/* ------    Start component   ------*/

import React from 'react';

interface ITableNumbers{
    className?: string,
    tableName: string,
    tableNumFir: string,
    tableNumSec: string,
    tableNumThird: string,
    lack: string,
    tableNumForth: string,
}
const TableNumbers: React.FC<ITableNumbers> = ({className, tableName, tableNumFir, tableNumSec, tableNumThird, lack, tableNumForth  })  => {
    return (
        <div className="text-white pt-9">
            <div className="table">
            <div className="row">
                <span className="first">{tableName}</span>
                <span className="fill">{tableNumFir}</span>
                <span className="fill">{tableNumSec}</span>
                <span className="fill">{tableNumThird}</span>
            </div>
            <div className="row">
                <span className="first">{lack}</span>
                <span className="num">{tableNumForth}</span>
            </div>
            </div>
        </div>
    );
}
 
export default TableNumbers;