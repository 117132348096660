import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SelectHolder from '../SelectHolder';
import Input from '../Field';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RSelect from '../Select';

function FormPage() {
  const Schema = Yup.object({
    email: Yup.string()
      .email('Invalid Email !')
      .min(4)
      .required('Please, Enter your Email !'),
    name: Yup.string().required('Field is required')
    // selectedOption: Yup.object().required('Please select an option'),
  });
  const formik: any = useFormik<any>({
    initialValues: {
      name: '',
      email: '',
      message: '',
      month: null,
      day: null,
      year: null
    },
    validationSchema: Schema,
    validateOnChange: true,
    validate: (value) => {
      const errors: { [key: string]: string } = {};
      if (!value.month?.value) {
        errors['month'] = 'Please select an option';
      }
      if (!value.day?.value) {
        errors['day'] = 'Please select an option';
      }
      if (!value.year?.value) {
        errors['year'] = 'Please select an option';
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      window.location.href= '/result'
    }
  });

  const handleSelectChange = (selectedOption: any, e: any) => {
    formik.setFieldValue([e.name], selectedOption);
  };

  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
    const yearValue = currentYear - index;
    return { value: yearValue.toString(), label: yearValue.toString() };
  });
  
  

  const month = [
    { value: 'Month', label: 'Month' },
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: ' April', label: ' April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },
  ];

  const day = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
  ];

  return (
    <div className="max-w-calcWidth m-auto px-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4 md:mb-6">
          <label
            className="block text-white font-bold mb-2"
            htmlFor="FULL BIRTH NAME *"
          >
            FULL BIRTH NAME *
          </label>
          <Input
            className="shadow appearance-none border-none bg-bgInput rounded w-full py-3 sm:py-5 px-4 text-white leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Enter your first name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.name}
          />
          {formik?.touched?.name && formik.errors.name && (
            <div className="pt-1 text-white">{formik.errors.name}</div>
          )}
        </div>
        <div className="mb-4 md:mb-6">
          <label
            className="block text-white font-bold mb-2"
            htmlFor="FULL BIRTH NAME *"
          >
            DATE OF BIRTH *
          </label>
          <div className="block -mx-.5 sm:flex sm:justify-between">
            <span className="min-w-mobileSelect mb-5 md:min-w-allWidth px-.5 sm:mb-0">
              <RSelect
                name="month"
                value={formik.values.month}
                error={formik.errors.month}
                touched={formik?.touched?.month}
                placeholder="Month"
                options={month}
                onChange={handleSelectChange}
              />
            </span>
            <span className="min-w-mobileSelect md:min-w-allWidth px-.5">
              <RSelect
                name="day"
                value={formik.values.day}
                error={formik.errors.day}
                touched={formik?.touched?.day}
                placeholder="Day"
                options={day}
                onChange={handleSelectChange}
              />
              {formik?.touched?.selectedOption && formik.errors.selectedOption&& (
                <div className='pt-1 text-white'>
                  {formik.errors.selectedOption}
                </div>
              )}
            </span>
            <span className="min-w-mobileSelect md:min-w-allWidth px-.5">
              <RSelect
                name="year"
                value={formik.values.years}
                error={formik.errors.years}
                touched={formik?.touched?.years}
                placeholder="Year"
                options={years}
                onChange={handleSelectChange}
              />
              {formik?.touched?.selectedOption && formik.errors.selectedOption&& (
                <div className='pt-1 text-white'>
                  {formik.errors.selectedOption}
                </div>
              )}
            </span>
          </div>
        </div>
        <div className="mb-4 md:mb-8">
          <label
            className="block text-white font-bold mb-2"
            htmlFor="EMAIL ADDRESS (OPTIONAL)"
          >
            EMAIL ADDRESS (OPTIONAL)
          </label>
          <Input
            className="shadow appearance-none border-none bg-bgInput rounded w-full py-3 sm:py-5  px-4 text-white leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="EMAIL ADDRESS (OPTIONAL)"
            name="email"
            onChange={formik.handleChange}
          />
          {formik?.touched?.email && formik.errors.email && (
            <div className="pt-1 text-white">{formik.errors.email}</div>
          )}
        </div>
        <div>
          <button
            type="submit"
            onClick={() => formik.handleSubmit()}
            className="flex items-center w-full  bg-btnColor justify-center text-white  py-3 md:py-4 leading-4 text-xs bd:text-base rounded-lg md:mx-0 "
          >
            CALCULATE
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormPage;