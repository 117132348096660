import React from 'react'

interface props {
    onClick?: Function;
}

const Welcome:React.FC<props> = (props) => {
    const {onClick} = props;

  return (
    <div className='welcome-screen bg-no-repeat bg-cover overflow-auto bg-bgPosition h-screen flex justify-center items-center flex-col fixed inset-0 z-50' style={{ backgroundImage: `url('assets/images/bg-welcome.jpg')` }}>
        <div className='text-center max-w-md mx-auto p-5 min-h-0'>
            <div className='w-32 md:w-44 mx-auto mb-6'>
                <img src="assets/images/logo-new.svg" alt="Pixiu" className='w-full h-auto block'/>
            </div>
            <h1 className="font-extrabold sm:text-xl text-4xl lg:text-4xl font-k2D uppercase md:mb-5 mb-2 text-transparent bg-clip-text bg-gradient-to-r from-textForm to-textPeach mb-6">WELCOME TO THE Pixiu temple</h1>
            <span onClick={()=> onClick?.()} className="md:text-base inline-block bg-btnColor text-xs font-Poppins text-white md:pt-4 md:pb-5 md:px-8 sm:px-5 sm:py-4 px-2 py-2 rounded-lg cursor-pointer mb-5">ENTER TEMPLE</span>
        </div>
    </div>
  )
}

export default Welcome