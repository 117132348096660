const Twitter = (props: React.SVGAttributes<SVGElement>) => {
  const { width = '24', height = '20', fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6435 2.93268C22.8085 3.30268 21.9115 3.55268 20.9685 3.66568C21.9415 3.08347 22.6694 2.16715 23.0165 1.08768C22.1023 1.63067 21.1018 2.01288 20.0585 2.21768C19.3569 1.46855 18.4276 0.972009 17.4148 0.805156C16.4021 0.638304 15.3626 0.810474 14.4577 1.29494C13.5529 1.7794 12.8333 2.54904 12.4107 3.48439C11.988 4.41973 11.886 5.46843 12.1205 6.46768C10.2682 6.37468 8.45613 5.89323 6.80192 5.05459C5.14772 4.21594 3.68834 3.03884 2.5185 1.59968C2.1185 2.28968 1.8885 3.08968 1.8885 3.94168C1.88805 4.70868 2.07693 5.46392 2.43838 6.14041C2.79982 6.8169 3.32266 7.39372 3.9605 7.81968C3.22078 7.79615 2.49737 7.59627 1.8505 7.23668V7.29668C1.85043 8.37242 2.22253 9.41506 2.90368 10.2477C3.58483 11.0803 4.53307 11.6516 5.5875 11.8647C4.90128 12.0504 4.18184 12.0778 3.4835 11.9447C3.781 12.8703 4.3605 13.6797 5.14087 14.2596C5.92124 14.8395 6.86342 15.1609 7.8355 15.1787C6.18533 16.4741 4.14739 17.1768 2.0495 17.1737C1.67788 17.1738 1.30658 17.1521 0.9375 17.1087C3.06698 18.4779 5.54584 19.2045 8.0775 19.2017C16.6475 19.2017 21.3325 12.1037 21.3325 5.94768C21.3325 5.74768 21.3275 5.54568 21.3185 5.34568C22.2298 4.68666 23.0164 3.87058 23.6415 2.93568L23.6435 2.93268V2.93268Z"
        fill="url(#paint0_linear_4902_525)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4902_525"
          x1="3.19004"
          y1="9.82556"
          x2="22.6025"
          y2="-1.91241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#733381" />
          <stop offset="1" stopColor="#FB9A27" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Twitter;
