import { Cross } from '../assets/svgs';

type Props = {
  tabs: { label: string; value: string }[];
  onClose: (item: { label: string; value: string }) => void;
};

const CloseableTabs = (props: Props) => {
  const { tabs } = props;
  return (
    <div className='mb-2'>
      <div className='filters-container m-auto flex flex-wrap'>
        {tabs.map((tab, i) => {
          return (
            <button className="tab_btn " key={i}>
              {tab.label}
              <Cross
                width={8}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props?.onClose(tab);
                }}
              />
            </button>
          );
        })}
      </div>  
    </div>
  );
};

export default CloseableTabs;
