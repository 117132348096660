import { Link } from 'react-router-dom';
import formArea from '../Formpage';
import {
  Cross,
  Discord,
  Instargram,
  MEFooter,
  Twitter
} from '../../assets/svgs';
import { MobileMenusvg, Wallet, WalletMobile } from '../../assets/svgs';
import RAudioPlayer from '../AudioPlayer/RAudioPlayer';
import React from 'react';

interface props {
  onClick?: Function;
}

const Header:React.FC<props> = ({onClick}) => {

  const NavMenu = [
    { menu: 'HOME', link: "/",  },
    { menu: 'NUMEROLOGY', link: "/numerology" },
    { menu: 'ARENA', link: "/arena" },
    { menu: 'STORE', link: "/store" },
    { menu: 'TRAVEL', link: "/travel" }
  ];

  return (
    <>
      <div className="header bg-backgroundColor border-borderColor border-b py-6 backdrop-blur-headerBlur">
        <div className="max-w-containerLarge m-auto flex items-center px-4 justify-between relative">
          <strong className="logo max-w-logoWidth">
            <Link to="/" onClick={()=> onClick?.()}>
              <img
                src="assets/images/logo.svg"
                alt="logo"
                className="w-20 md:w-28"
              />
            </Link>
          </strong>
          <div className="nav-drop fixed md:static">
            <div className="nav-area relative">
              <div className="relative nav-wrap p-8 md:p-0 flex flex-wrap flex-col justify-between">
                <div className="relative">
                  <div onClick={()=> document.body.classList.remove('menu-active')}
                    className="mb-10 cursor-pointer inline-block md:hidden"
                  >
                    <Cross />
                  </div>
                  <ul className="md:flex">
                    {NavMenu.map((data) => (
                      <li key={data.menu} className=" mb-2 sm:mb-0 md:ml-.1 lg:ml-4  md:mr-3">
                        <Link
                          to={data.link}
                          className="px-3 py-2 rounded-lg text-white font-semibold hover:bg-menuColor  ease-in-out duration-300"
                        >
                          {data.menu}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="relative md:hidden">
                  <ul className="flex items-center justify-center mb-3">
                    <li className="mx-2">
                      <Link to="/">
                        <Discord />
                      </Link>
                    </li>
                    <li className="mx-2">
                      <Link to="/">
                        <Twitter />
                      </Link>
                    </li>
                    <li className="mx-2">
                      <Link to="/">
                        <Instargram />
                      </Link>
                    </li>
                    <li className="mx-2">
                      <Link to="/">
                        <MEFooter />
                      </Link>
                    </li>
                  </ul>
                  <div className="text-center">
                    <p className="text-white mb-2 text-footerText">
                      POWERED BY PIXIUVERSE
                    </p>
                    <p className="block text-txtClr text-footerText">
                      @ 2023 PIXIUVERSE ALL RIGHTS RESERVED.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center md:hidden">
            <div
              onClick={()=> document.body.classList.add('menu-active')}
              className="menu-bar md:hidden cursor-pointer"
            >
              <MobileMenusvg />
            </div>
          </div>
          <RAudioPlayer />
        </div>
      </div>
    </>
  );
};

export default Header;
