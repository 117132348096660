import React, { useState } from 'react';

function Search({ onSearch, handleSearch }: any) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearch(value)
  };

  return (
    <div className="border-search">
      <input
        type="text"
        placeholder="Search"
        onChange={handleChange}
        className="searchbar py-2 px-2 rounded bgSearch outline-none "
      />
    </div>
  );
}

export default Search;
