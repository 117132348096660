import React, { useState } from 'react';
import MyButton from '../MyButton';
import Calculate from '../Calculate';
import CalcultaElement from '../CalcultaElement';

const BannerResult = () => {
  const [active, setActive] = useState(false);
  return (
    <>
      <div className="pt-2 md:pt-8 lg:pt-0">
        <div className="result  pb-2 md:pb-7  pt-11 max-w-containerLarge m-auto block lg:flex items-center px-4 justify-between border-b-2">
          <div className="w-full md:w-1/5 -mt-8">
            <img
              src="assets/images/img-snack.png"
              className="h-auto max-w-full image-radius"
              alt="dragon"
            />
          </div>
          <div className="lg:w-2/3 pt-3 lg:pt-0 lg:pl-4 md:pr-6 xl:pr-30 lg:pb-0 pb-6">
            <div className="mb-1">
              <h1 className="block lg:pt-3 sm:font-extrabold text-fGray font-k2D text-2xl  xl:text-4xl bg-clip-text text-transparent bg-gradient-to-r from-textForm to-textPeach mb-3">
                NUMEROLOGY RESULT
              </h1>
              <p className="text-white mb-4 font-k2D text-base">
                JOHN DOE (12-02-1996)
              </p>
            </div>
            <div className="">
              <strong className="block text-fGray font-semibold mb-1 text-base ">
                [Summary of the Numerology Reading]
              </strong>
              <p className="text-fGray mb-.5 font-normal text-base">
                The numbers of your life reveal a detailed and dynamic pattern, telling the story of your journey through life. Guided by the principles of numerology, this report provides insight into your innate tendencies, strengths, and areas for potential growth.
               </p>
               <p className="text-fGray mb-.5 font-normal text-base">
               Your Life Path Number, calculated from your birth date, is 4. This number reflects the path you'll take, your abilities, and the opportunities and challenges you'll encounter.
               </p>
            </div>
          </div>
          <div className="w-1-3 element">
            <div className={`mb-3 w-full sm:w-btnWidth ${!active && 'activeButton'}`}  onClick={() => setActive(false)}>
              <MyButton Btntext="My Numbers" />
            </div>
            <div className={`w-full sm:w-btnWidth ${active && 'activeButton'}`} onClick={() => setActive(true)}>
              <MyButton Btntext="My Elements" />
            </div>
          </div>
        </div>
      </div>
      {!active ? <Calculate /> : <CalcultaElement />}
    </>
  );
};

export default BannerResult;
