const Back = (props: React.SVGAttributes<SVGElement>) => {
  const { width = '32', height = '32', fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49309 15.2929C2.10256 15.6834 2.10256 16.3166 2.49309 16.7071L8.85705 23.0711C9.24757 23.4616 9.88074 23.4616 10.2713 23.0711C10.6618 22.6805 10.6618 22.0474 10.2713 21.6569L4.61441 16L10.2713 10.3431C10.6618 9.95262 10.6618 9.31946 10.2713 8.92893C9.88074 8.53841 9.24757 8.53841 8.85705 8.92893L2.49309 15.2929ZM28.8002 17C29.3525 17 29.8002 16.5523 29.8002 16C29.8002 15.4477 29.3525 15 28.8002 15V17ZM3.2002 17H28.8002V15H3.2002V17Z"
        fill="white"
      />
    </svg>
  );
};
export default Back;
