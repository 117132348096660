import { Link } from "react-router-dom";

interface ICardButton{
    className?: string,
    btnText: string,
    img?: string,
    disabled?: boolean
}
const CardButton: React.FC<ICardButton> = ({className, btnText, img, disabled = false })  => {
    return (
      <div className="px-2 flex-1 py-1 min-w-[100px] md:min-w-[inherit]">
        <button type="button" disabled={disabled}
          className="font-JoSefin-sans flex items-center bg-btnBgClr text-white py-2 w-full px-4 rounded-lg text-sm md:text-cardtext font-medium"
        >
          <span className="mr-2 ">
            <img src={img} alt="Simg" />
          </span>
          {btnText}
        </button>
      </div>
    );
}
 
export default CardButton;