import { useEffect, useState } from 'react';
import AudioPlayer from '../../Components/AudioPlayer/RAudioPlayer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Home from '../../Components/Home';
import Welcome from '../../Components/Welcome';

const LeaderBoard = () => {
  const [welcome, setWelcome] = useState(true);
  useEffect (()=> {
    setWelcome(false)
  },[])
  return (
    <>
      <div
        className="flex justify-between flex-col wrapper bg-no-repeat bg-cover bg-bgPosition overflow-hidden"
        style={{ backgroundImage: `url('assets/images/bg-home.png')` }}
      >
        <Welcome
          onClick={() => {
            setWelcome(false);
            document.body.classList.add('page-active');
          }}
        />
        {!welcome && (
          <>
            <Header />
            <div className="main-section flex-grow-1 basis-0 relative">
              <Home />
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default LeaderBoard;
