import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Detail from '../../Components/Detail';
import { Link } from 'react-router-dom';


const Merch = () => {
  return (
    <>
      <div
      className="flex justify-between flex-col wrapper bg-no-repeat bg-cover bg-bgPosition overflow-hidden"
      style={{ backgroundImage: `url('assets/images/bg-scroll.png')` }}
      >
      <Header />
      <div className='main-section flex-grow-1 basis-0'>
      <div className="max-w-homeContainer mx-auto px-4">
        <div className="two-cols mx-auto justify-between pt-5 md:pt-14 mb-5 md:pb-28 flex flex-col-reverse sm:flex-row"
        >
            <div className="col relative sm:justify-center sm:flex">
                <div className="relative flex justify-center image bg-btnColor p-2 rounded-xl">
                    <img className="w-full" src="assets/images/bracelet-3.webp" alt="img" />
                </div>
            </div>
            <div className="w-full sm:w-3/5 num mb-3 md:pl-8 lg:pl-12 pl-3 sm:pt-0">
               <Link to="/Store">
                    <div className="image flex items-center md:mb-5 mb-1 sm:mb-3">
                        <img  src="assets/images/left-arrow.svg" alt="img" />
                        <p className="text-white font-Poppins px-2 text-xs sm:text-sm">Back</p>
                    </div>
               </Link>
                <p className="text-white font-k2D font-extrabold text-base sm:text-xl md:mb-2 mb-1">FEATURED</p>
                <div className="text-holder">
                    <h1 className="text block font-k2D font-extrabold md:text-4xl text-3xl uppercase md:mb-8 mb-1 text-white">Merch</h1>
                    <p className="md:text-base paragraph text-xs text-fGray mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Curabitur id convallis arcu, ut blandit justo. Nulla faucibus mauris scelerisque leo pretium, 
                    eu mattis erat ultricies. Quisque iaculis eu ex non pretium. In hac habitasse platea dictumst. 
                    Maecenas tempus diam a luctus consequat. Cras ultricies rutrum tortor. Nulla lacinia tellus ut erat 
                    fringilla tempus. Aenean et eleifend diam. Cras bibendum pulvinar dui, nec commodo nibh lacinia nec.</p>
                    <div className="flex items-center justify-between  ">

                           {/* <div className="price">
                            <p className="text-white font-k2D font-extrabold md:text-base text-xs">PRICE</p>
                                <div className="flex items-center">
                                    <img src="assets/images/price.svg" alt="img"/>
                                    <ul className="num ml-3">
                                        <li className="p-2">
                                        <Link
                                            to="/"
                                            className="text-white font-JoSefin-sans font-medium md:text-2xl text-sm"
                                            >
                                            0.439 
                                        </Link>
                                        </li>
                                    </ul>
                                </div>
                           </div> */}
                        <Link
                            to="/"
                            className="text-base items-center font-JoSefin-sans bg-btnColor text-white md:px-10 md:py-4 px-5 py-2 rounded-lg"
                            >
                            BUY NOW
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
      <Footer />
      </div>
    </>
    );
}

export default Merch;
