import React, { useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

const MySlider = () => {
  const [currentSlide, setCurrentSlide] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<any>("match");
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    focusOnSelect: true,
    autoplaySpeed: 4000,
    arrows: false,
    centerPadding: '0',
    centerMode: true,
    afterChange: () => {
      const slide = document.querySelector(`.slick-center [data-id]`);
      const dataId = slide?.getAttribute("data-id");
      setCurrentPage(dataId);
    },
  };
  console.log(currentPage);
  return (
    <div className="max-w-arenaWidth m-auto">
      <Slider {...settings}>
        <div data-id="match">
          <div className="slide">
            <div className="bg-slide p-4">
              <img
                src="assets/images/slide-3.png"
                className="h-auto max-w-full"
                alt="dragon"
              />
            </div>
          </div>
          <div className="hide-text">
            <div className="text-center pt-3.5">
              <h2 className="text-white text-xs md:text-xl">Match4</h2>
            </div>
          </div>
        </div>
        <div data-id="arenaDetail">
          <div className="slide">
            <div className="bg-slide p-4">
              <img
                src="assets/images/slide-1.png"
                className="h-auto max-w-full"
                alt="dragon"
              />
            </div>
          </div>
          <div className="hide-text">
            <div className="text-center pt-3.5">
              <h2 className="text-white text-xs md:text-xl">DragonBound</h2>
            </div>
          </div>
        </div>
        <div data-id="arenaDetail">
          <div className="slide">
            <div className="bg-slide p-4">
              <img
                src="assets/images/slide-2.png"
                className="h-auto max-w-full"
                alt="dragon"
              />
            </div>
          </div>
          <div className="hide-text">
            <div className="text-center pt-3.5">
              <h2 className="text-white text-xs md:text-xl">DragonBound</h2>
            </div>
          </div>
        </div>
        <div data-id="match">
          <div className="slide">
            <div className="bg-slide p-4">
              <img
                src="assets/images/slide-3.png"
                className="h-auto max-w-full"
                alt="dragon"
              />
            </div>
          </div>
          <div className="hide-text">
            <div className="text-center pt-3.5">
              <h2 className="text-white text-xs md:text-xl">DragonBound</h2>
            </div>
          </div>
        </div>
        <div data-id="arenaDetail">
          <div className="slide">
            <div className="bg-slide p-4">
              <img
                src="assets/images/slide-1.png"
                className="h-auto max-w-full"
                alt="dragon"
              />
            </div>
          </div>
          <div className="hide-text">
            <div className="text-center pt-3.5">
              <h2 className="text-white text-xs md:text-xl">DragonBound</h2>
            </div>
          </div>
        </div>
        <div data-id="arenaDetail">
          <div className="slide">
            <div className="bg-slide p-4">
              <img
                src="assets/images/slide-2.png"
                className="h-auto max-w-full"
                alt="dragon"
              />
            </div>
          </div>
          <div className="hide-text">
            <div className="text-center pt-3.5">
              <h2 className="text-white text-xs md:text-xl">DragonBound</h2>
            </div>
          </div>
        </div>
      </Slider>
      <div className="pt-2 md:pt-6">
        <button
          onClick={() => navigate(`/${currentPage}`)}
          className="flex btn items-center bg-btnColor justify-center text-white py-2 lg:py-4 leading-4 text-xs bd:text-base rounded-lg md:mx-0 "
        >
          OVERVIEW
        </button>
      </div>
    </div>
  );
};

export default MySlider;
