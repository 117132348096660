import React, { ChangeEventHandler, FocusEventHandler, useState } from 'react'

interface IInput {
  className?: string;
  label?: string;
  variation?: 'primary' | 'secondary';
  extralink?: string;
  type?: string;
  id?: string;
  linkClick?: Function;
  placeholder?:string
  name?: string;
  value?: string;
  error?: any;
  touched?: any;
  onChange?: ChangeEventHandler<any> | undefined
  onBlur?: FocusEventHandler<any> | undefined
  onTouch?: Function
}
const Input: React.FC<IInput> = (Props) => {
  const {
    className,
    linkClick,
    label,
    name,
    id,
    onChange,
    onBlur,
    error,
    value,
    placeholder,
    touched,
    extralink,
    type = 'text',
    variation = 'primary',
  } = Props;
   
  return (
    <input
    className={`shadow appearance-none border-none bg-bgInput rounded w-full py-3 sm:py-5 px-4 text-white leading-tight focus:outline-none focus:shadow-outline ${className}`}
    id={id}
    type={type}
    placeholder={placeholder}
    name={name}
    value={value}
    onChange={onChange}
    />
  )
}

export default Input