import React from 'react';
import CircleImage from '../Circuler';
import TableElement from '../TableElement';

const CalcultaElement = () => {
  return (
    <div className="max-w-containerLarge m-auto px-4">
      <div className="block lg:flex pt-2 justify-between pb-4 md:pb-10">
        <div className="w-full sm:w-1/3">
          <CircleImage
            imgPlace={
              <img
                src="assets/images/light.png"
                className="w-20 image-radius"
                alt="Circle"
              />
            }
            textPlace={
              <span className="text-base block pl-10">
                <span className="text-fGray">Your</span> Element
                <span className="text-fGray"> is</span> Water
              </span>
            }
          />
        </div>
        <div className="w-full pt-6  md:pl-4 lg:w-3/4">
          <div className="mb-8">
            <h2 className="mb-0 text-white text-2xl font-bold">MY ELEMENTS</h2>
          </div>
          <p className="mb-0 text-fGray leading-6">
            Being a Water element, you are intuitive, emotional, and deeply creative. Your sensitivity enables a strong understanding of others, 
            making you compassionate and nurturing. Your numerological elements offer valuable insights into your personality and life's purpose.
          </p>
          <TableElement />
          <div className="pt-9">
            <h3 className="mb-4  text-white">MY ELEMENTS</h3>
            <p className="mb-0 text-fGray leading-6">
              The elements in your chart provide a comprehensive view of your life. They highlight your strengths, weaknesses, 
              and the areas where you can achieve the most growth.
            </p>
          </div>
          <div className="pt-9">
            <h3 className="mb-4 text-white">MY REQUIRED ELEMENTS</h3>
            <p className="mb-0 text-fGray leading-6">
              Your required elements are those that you need to focus on to achieve balance and harmony in your life. They serve as guides, directing you towards personal growth and fulfillment.
            </p>
          </div>
          <div className="pt-9">
            <h3 className="mb-4 text-white">WHAT NUMBERS I SHOULD AVOID</h3>
            <p className="mb-0 text-fGray leading-6">
              Certain numbers may not resonate well with your energy, potentially leading to challenges or disruptions. Recognizing these numbers can help you navigate your life path more smoothly, avoiding potential pitfalls along the way.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalcultaElement;
