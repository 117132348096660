import ComingSoon from "../../Components/ComingSoon";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const Travel = () => {
    return (
        <>
            <div
            className="flex justify-between flex-col wrapper overflow-hidden bg-no-repeat bg-cover bg-storePosition"
            style={{ backgroundImage: `url('assets/images/bg-travel.png')` }}>
                <div className="flex flex-col justify-between h-full">
                <Header />
                <div className='main-section flex-grow-1 basis-0'>
                    <ComingSoon />
                </div>
                <Footer />
                </div>
            </div>
       </>
    );
}
 
export default Travel;